
@use "node_modules/ngx-toastr/toastr.css";
@use "node_modules/ngx-spinner/animations/ball-spin.css";

html,
body {
   margin: 0;
   padding: 0;
   font-family: "Roboto", sans-serif;
}
@font-face {
   font-family: "Roboto", sans-serif;
   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
   font-weight: 400;
   font-style: normal;
}

.spacing {
   flex: 1 1 auto;
}

.full-width {
   width: 100%;
}
.flex-center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.space-around {
   align-content: space-around;
}
.row {
   display: flex;
   flex-direction: row;
}

.col {
   flex: 1;
   margin-right: 20px;
   margin-left: 20px;
}

mat-error {
   padding-top: 7px;
}

mat-form-field {
   padding-bottom: 12px;
}
